<template>
  <div class="develop-reset-collections">
    <confirm ref="deleting" title="データベースのリセット" :msg="'コレクション名「' + this.checkedCollections + '」をリセットします'" @do-action="resetCollections()" />
    <p class="develop-reset-collections__title">コレクションリセット</p>
    <p class="develop-reset-collections__description">リセットするコレクションを選択してください</p>
    <div class="develop-reset-collections__collections">
      <div v-for="collection in collections" :key="collection">
        <input class="develop-reset-collections__collections__box" type="checkbox" :id="collection" :value="collection" v-model="checkedCollections" >
        <label class="develop-reset-collections__collections__label" :for="collection">{{ collection }}</label>
      </div>
    </div>
    <v-btn rounded depressed :disabled="!canSubmit" class="develop-reset-collections__btn"
           @click="$refs.deleting.open()">コレクションをリセット
    </v-btn>
  </div>
</template>

<script>
import confirm from '@/components/common/confirm.vue'

export default {
  components: { confirm },
  data () {
    return {
      // 選択したコレクションの配列
      checkedCollections: []
    }
  },
  async mounted () {
    // 本番環境でURL直打ちなどで遷移してきた場合homeに遷移させる
    if (this.isProduction) {
      this.$store.commit('setTelop', { show: true, msg: '本番環境では利用できません', type: 'error' })
      this.$router.push({ name: 'home' })
    }

    // コレクション名一覧が未取得であるなら取得する
    if (this.collections.length === 0) await this.$store.dispatch('functions/getAllCollections')

    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 本番環境かどうか
     */
    isProduction () {
      return process.env.NODE_ENV === 'production'
    },
    /**
     * @return {String[]} コレクション名一覧
     */
    collections () {
      return this.$store.getters.collections
    },
    /**
     * @return {Boolean} コレクションリセットができるかどうか
     */
    canSubmit () {
      return this.checkedCollections.length !== 0
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * コレクションのリセット
     */
    async resetCollections () {
      this.$store.commit('setProcessing', true)

      await this.$store.dispatch('functions/resetCollections', this.checkedCollections)

      // 削除したコレクション名をコレクション一覧から削除
      const collections = this.collections.filter(collectionName => !this.checkedCollections.includes(collectionName))
      this.$store.commit('setCollections', collections)

      this.$store.commit('setTelop', { show: true, msg: 'コレクションのリセットが完了しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.develop-reset-collections {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
  &__title {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  &__description {
    margin: 10px 0 0 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__collections {
    margin: 20px 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    &__box {
      width: 1.6rem;
      height: 1.6rem;
      margin: 10px 0;
      vertical-align: middle;
    }
    &__label {
      margin: 0 0 0 5px;
    }
  }
  &__btn {
    display: block;
    width: 200px;
    margin: 50px auto 30px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: white;
    &.v-btn:not(.v-btn--round) {
      height: 30px;
    }
    &.theme--light.v-btn:not(.v-btn--flat) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat) {
      color: white !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
